import enCommon from './en/common.json';
import frCommon from './fr/common.json';

import enAboutUs from './en/about-us.json';
import frAboutUs from './fr/about-us.json';

import enContactUs from './en/contact-us.json';
import frContactUs from './fr/contact-us.json';

import enBrokerCentre from './en/broker-centre.json';
import frBrokerCentre from './fr/broker-centre.json';

import enHome from './en/home.json';
import frHome from './fr/home.json';

import enBrokerPortal from './en/broker-portal.json';
import frBrokerPortal from './fr/broker-portal.json';

import enCareers from './en/careers.json';
import frCareers from './fr/careers.json';

import enElectronicAccessAgreement from './en/electronic-access-agreement.json';
import frElectronicAccessAgreement from './fr/electronic-access-agreement.json';

import enPrivacyStatement from './en/privacy-statement.json';
import frPrivacyStatement from './fr/privacy-statement.json';

import enAccessibility from './en/accessibility.json';
import frAccessibility from './fr/accessibility.json';

import enTermsConditions from './en/terms-conditions.json';
import frTermsConditions from './fr/terms-conditions.json';

import enRegulatoryInformation from './en/regulatory-information.json';
import frRegulatoryInformation from './fr/regulatory-information.json';

import enDeposits from './en/deposits.json';
import frDeposits from './fr/deposits.json';

import enBorrower from './en/borrower.json';
import frBorrower from './fr/borrower.json';

import enPromotions from './en/promotions.json';
import frPromotions from './fr/promotions.json';

import enMortgageServicingDocuments from './en/mortgage-servicing-documents.json';
import frMortgageServicingDocuments from './fr/mortgage-servicing-documents.json';

import enCustomerCentre from './en/customer-centre.json';
import frCustomerCentre from './fr/customer-centre.json';

import enSecondsPlusMortgage from './en/seconds-plus-mortgage.json';
import frSecondsPlusMortgage from './fr/seconds-plus-mortgage.json';

import enVideoLibrary from './en/video-library.json';
import frVideoLibrary from './fr/video-library.json';

import enLegalDisclaimer from './en/legal-disclaimer.json';
import frLegalDisclaimer from './fr/legal-disclaimer.json';

import enInstantVerification from './en/instant-verification.json';
import frInstantVerification from './fr/instant-verification.json';

import enCCP from './en/ccp.json';
import frCCP from './fr/ccp.json';

import enSocial from './en/social.json';
import frSocial from './fr/social.json';

export const LocaleNameSpaceKeys = {
  COMMON: 'COMMON',
  HOME: 'HOME',
  BROKER_CENTRE: 'BROKER_CENTRE',
  BROKER_PORTAL: 'BROKER_PORTAL',
  MORTGAGE_ORIGINATIONS_TEAM: 'MORTGAGE_ORIGINATIONS_TEAM',
  ABOUT_US: 'ABOUT_US',
  CONTACT_US: 'CONTACT_US',
  CAREERS: 'CAREERS',
  ELECTRONIC_ACCESS_AGREEMENT: 'ELECTRONIC_ACCESS_AGREEMENT',
  PRIVACY_STATEMENT: 'PRIVACY_STATEMENT',
  ACCESSIBILITY: 'ACCESSIBILITY',
  TERMS_CONDITIONS: 'TERMS_CONDITIONS',
  REGULATORY_INFORMATION: 'REGULATORY_INFORMATION',
  DEPOSITS: 'DEPOSITS',
  QUEBEC: 'QUEBEC',
  BORROWER: 'BORROWER',
  PROMOTIONS: 'PROMOTIONS',
  MORTGAGE_SERVICING_DOCUMENTS: 'MORTGAGE_SERVICING_DOCUMENTS',
  CUSTOMER_CENTRE: 'CUSTOMER_CENTRE',
  SECONDS_PLUS_MORTGAGE: 'SECONDS_PLUS_MORTGAGE',
  VIDEO_LIBRARY: 'VIDEO_LIBRARY',
  LEGAL_DISCLAIMER: 'LEGAL_DISCLAIMER',
  INSTANT_VERIFICATION: 'INSTANT_VERIFICATION',
  CCP: 'CCP',
  SFD: 'SFD',
  SOCIAL: 'SOCIAL',
};

export const commonTranslations = {
  en: {
    [LocaleNameSpaceKeys.COMMON]: { ...enCommon },
    [LocaleNameSpaceKeys.CONTACT_US]: { ...enContactUs },
    [LocaleNameSpaceKeys.ABOUT_US]: { ...enAboutUs },
    [LocaleNameSpaceKeys.BROKER_CENTRE]: { ...enBrokerCentre },
    [LocaleNameSpaceKeys.HOME]: { ...enHome },
    [LocaleNameSpaceKeys.BROKER_PORTAL]: { ...enBrokerPortal },
    [LocaleNameSpaceKeys.CAREERS]: { ...enCareers },
    [LocaleNameSpaceKeys.ELECTRONIC_ACCESS_AGREEMENT]: { ...enElectronicAccessAgreement },
    [LocaleNameSpaceKeys.PRIVACY_STATEMENT]: { ...enPrivacyStatement },
    [LocaleNameSpaceKeys.ACCESSIBILITY]: { ...enAccessibility },
    [LocaleNameSpaceKeys.TERMS_CONDITIONS]: { ...enTermsConditions },
    [LocaleNameSpaceKeys.REGULATORY_INFORMATION]: { ...enRegulatoryInformation },
    [LocaleNameSpaceKeys.DEPOSITS]: { ...enDeposits },
    [LocaleNameSpaceKeys.BORROWER]: { ...enBorrower },
    [LocaleNameSpaceKeys.PROMOTIONS]: { ...enPromotions },
    [LocaleNameSpaceKeys.MORTGAGE_SERVICING_DOCUMENTS]: { ...enMortgageServicingDocuments },
    [LocaleNameSpaceKeys.CUSTOMER_CENTRE]: { ...enCustomerCentre },
    [LocaleNameSpaceKeys.SECONDS_PLUS_MORTGAGE]: { ...enSecondsPlusMortgage },
    [LocaleNameSpaceKeys.VIDEO_LIBRARY]: { ...enVideoLibrary },
    [LocaleNameSpaceKeys.LEGAL_DISCLAIMER]: { ...enLegalDisclaimer },
    [LocaleNameSpaceKeys.INSTANT_VERIFICATION]: { ...enInstantVerification },
    [LocaleNameSpaceKeys.CCP]: { ...enCCP },
    [LocaleNameSpaceKeys.SOCIAL]: { ...enSocial },
  },
  fr: {
    [LocaleNameSpaceKeys.COMMON]: { ...frCommon },
    [LocaleNameSpaceKeys.CONTACT_US]: { ...frContactUs },
    [LocaleNameSpaceKeys.ABOUT_US]: { ...frAboutUs },
    [LocaleNameSpaceKeys.BROKER_CENTRE]: { ...frBrokerCentre },
    [LocaleNameSpaceKeys.HOME]: { ...frHome },
    [LocaleNameSpaceKeys.BROKER_PORTAL]: { ...frBrokerPortal },
    [LocaleNameSpaceKeys.CAREERS]: { ...frCareers },
    [LocaleNameSpaceKeys.ELECTRONIC_ACCESS_AGREEMENT]: { ...frElectronicAccessAgreement },
    [LocaleNameSpaceKeys.PRIVACY_STATEMENT]: { ...frPrivacyStatement },
    [LocaleNameSpaceKeys.ACCESSIBILITY]: { ...frAccessibility },
    [LocaleNameSpaceKeys.TERMS_CONDITIONS]: { ...frTermsConditions },
    [LocaleNameSpaceKeys.REGULATORY_INFORMATION]: { ...frRegulatoryInformation },
    [LocaleNameSpaceKeys.DEPOSITS]: { ...frDeposits },
    [LocaleNameSpaceKeys.BORROWER]: { ...frBorrower },
    [LocaleNameSpaceKeys.PROMOTIONS]: { ...frPromotions },
    [LocaleNameSpaceKeys.MORTGAGE_SERVICING_DOCUMENTS]: { ...frMortgageServicingDocuments },
    [LocaleNameSpaceKeys.CUSTOMER_CENTRE]: { ...frCustomerCentre },
    [LocaleNameSpaceKeys.SECONDS_PLUS_MORTGAGE]: { ...frSecondsPlusMortgage },
    [LocaleNameSpaceKeys.VIDEO_LIBRARY]: { ...frVideoLibrary },
    [LocaleNameSpaceKeys.LEGAL_DISCLAIMER]: { ...frLegalDisclaimer },
    [LocaleNameSpaceKeys.INSTANT_VERIFICATION]: { ...frInstantVerification },
    [LocaleNameSpaceKeys.CCP]: { ...frCCP },
    [LocaleNameSpaceKeys.SOCIAL]: { ...frSocial },
  },
};
